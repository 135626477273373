
import "./Footer.css";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TextField } from "@aws-amplify/ui-react";
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function Footer() {

    return (
        <>
            <footer style={{ color: "gray", marginBottom: "0px", width: '100%', height: '17vh' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} xl={3}>
                        <Item><div className="container footer-1">
                            <h2>TRADING HOURS</h2>


                            <h3>Monday-Thursday:  08:00-16:30</h3>
                            <h3>Friday:  08:00-15:00</h3>
                        </div></Item>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                        <Item><div className="container footer-2">
                            <h2>ADDRESS</h2>

                            <h3>6 Jules St, Jeppestown, Johannesburg, 2043</h3>
                            <h3>11 Hanau St, Jeppestown, Johannesburg, 2043</h3>
                        </div></Item>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                        <Item><div className="container footer-3">
                            <h2>CONTACT US</h2>

                            <h3>0116140505</h3>
                            <h3>info@novaslubricants.co.za</h3>
                        </div></Item>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                        <Item><div className="container footer-4">
                            <h2>LET US CALL YOU BACK!</h2>

                            <TextField
                                required
                                id="outlined-required"
                                defaultValue="Hello World" label={undefined}                            />
                            <Button variant="contained">Send</Button>
                        </div> </Item>
                    </Grid>
                </Grid>
            </footer>
        </>
    );
}