import { ContactUs } from "./pages/contact/contactUs";
import { FAQ } from "./pages/faq/faq";
import { Home } from "./pages/home/home"
import { ProductDetails } from "./pages/productDetails/productDetails"
import { Products } from "./pages/products/products";
import { ProductTypes } from "./pages/productTypes/productTypes"
import { Protected } from './pages/userHome/userHome';

export interface AppRoute{
    Navigation: string,
    DisplayName: string,
    Protected: boolean,
    Component: () => JSX.Element,
    NavBarVisibile: boolean
}

export const CustomRoutes : AppRoute[] = [
    {
        DisplayName: 'Home',
        Navigation: '/home',
        Protected: false,
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Products',
        Navigation: '/product-types',
        Protected: false,
        Component: ProductTypes,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Product Types',
        Navigation: '/products/:id',
        Protected: false,
        Component: Products,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Product Details',
        Navigation: '/products/:ptID/:id',
        Protected: false,
        Component: ProductDetails,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Contact Us',
        Navigation: '/contact-us',
        Protected: false,
        Component: ContactUs,
        NavBarVisibile: true
    },
    {
        DisplayName: 'FAQ',
        Navigation: '/faq',
        Protected: false,
        Component: FAQ,
        NavBarVisibile: true
    },
    
]

export const SettingRoutes : AppRoute[] = [
    {
        DisplayName: 'Profile',
        Navigation: '/profile',
        Protected: true,
        Component: Protected,
        NavBarVisibile: true
    },
]