import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, Heading, View } from '@aws-amplify/ui-react';
import NavBar from './navbar/Navbar';
import { Footer } from './footer/Footer';

export function Layout() {
  return (
    <>
      <NavBar />
      <div style={{minHeight: '90vh'}}>
        <div style={{ marginTop: '12vh', minHeight: '70vh' }}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
}