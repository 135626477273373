// components/Home.js
import { Heading } from '@aws-amplify/ui-react';
import { Link, useParams } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import { Store } from 'react-notifications-component';
import { useEffect, useState } from 'react';

export function ProductTypes() {


  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {

      setNotificationID(Store.addNotification({
        title: "Wonderful!",
        message: "teodosii@react-notifications-component",
        type: "warning",
        insert: "top",
        container: "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      }));

      Store.removeNotification(notificationID)
      await delay(1000);
      setIsLoaded(true)

    }
    getData()
  }, [])


  return (
    <>


      {isLoaded ? (
        <>
          <Heading level={3}>
            Products...

            <br />
            <Link to={`/products/123`}>
              <button type="button">
                Click Me!
              </button>
            </Link>
          </Heading>

        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><RingLoader color="#36d7b7" /></div>

        </div>
      )}
    </>
  );
}